import { useState } from "react";
import { SqButton, SqOutlineButton } from "./button";
import { InputBox, InputSelectBox, RadioBtn } from "./inputs";
import CustomModal from "./modal";

import DoneIcon from "../assets/images/booking-done.png"
import { useNavigate } from "react-router-dom";

function BillingForm(props) {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const navigate = useNavigate();

    const NavigateToHome = () => {
        navigate('/');
    };
    return (
        <>
            <div className="billing-form-container">
                <div className="form-heading">Confirm And Pay</div>
                <div className="form-section">
                    <div className="section-title">Your Booking Details</div>
                    <div className="form-row d-flex align-items-center justify-content-center row">
                        <div className="form-text col-6">
                            <div className="text">Dates</div>
                            <div className="text-sm-grey">Nov 16 - 18</div>
                        </div>
                        <div className="text-grey text-p col-6">Edit</div>
                    </div>
                    <div className="form-row d-flex align-items-center justify-content-center row">
                        <div className="form-text col-6">
                            <div className="text">Guests</div>
                            <div className="text-sm-grey">2 Adults, 1 Child</div>
                        </div>
                        <div className="text-grey text-p col-6">Edit</div>
                    </div>
                </div>
                {/* ------------------------------------------------------------- */}
                <div className="form-section">
                    <div className="section-title">Choose Payment Method</div>
                    <div className="form-row d-flex align-items-center justify-content-center row">
                        <div className="form-text col-6">
                            <div className="text">Pay in full</div>
                            <div className="text-sm-grey">Pay the total ($1468) now and you're all set</div>
                        </div>
                        <div className="text-grey col-6"><RadioBtn /></div>
                    </div>
                    <div className="form-row d-flex align-items-center justify-content-center row">
                        <div className="form-text col-6">
                            <div className="text">Pay part now, part later</div>
                            <div className="text-sm-grey">Pay the ($500) now and you're all set</div>
                        </div>
                        <div className="text-grey col-6"><RadioBtn /></div>
                    </div>
                </div>
                {/* ------------------------------------------------------------- */}
                <div className="input-form">
                    <div className="section-title">Credit/Debit Card</div>
                    <div className="row">
                        <div className="col-md-6"><InputBox type={"text"} label={"Card Number"} value={"8900 7827 9373 XXXX"} /></div>
                        <div className="col-md-6"><InputBox type={"text"} label={"Card Holder Name"} value={"Perry Wilson"} /></div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"><InputBox type={"text"} label={"Expiry Date"} value={"04/27"} /></div>
                        <div className="col-md-6"><InputBox type={"password"} label={"CVV"} value={929} /></div>
                    </div>
                    <div className="section-title">Billing Address</div>
                    <div className="row">
                        <div className="col-md-12"><InputBox type={"text"} label={"Name"} value={"Perry Wilson"} /></div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"><InputBox type={"email"} label={"Email Address"} value={"perry.wilson@gmail.com"} /></div>
                        <div className="col-md-6"><InputBox type={"text"} label={"Mobile Number"} value={"(021)-551-8272"} /></div>
                    </div>
                    <div className="row">
                        <div className="col-md-12"><InputBox type={"text"} label={"Flat, House no., Building, Company, Apartment"} value={"House No 23"} /></div>
                    </div>
                    <div className="row">
                        <div className="col-md-12"><InputBox type={"text"} label={"Area, Colony, Street, Sector, Village"} value={"Parker Rd, Allentown"} /></div>
                    </div>
                    <div className="row">
                        <div className="col-md-4"><InputSelectBox label={"City"} options={["New-Mexico"]} /></div>
                        <div className="col-md-4"><InputBox type={"text"} label={"Pin Code"} value={"272771"} /></div>
                        <div className="col-md-4"><InputSelectBox label={"State"} options={["United State of America"]} /></div>
                    </div>
                    <div className="section-title">Cancellation Policy</div>
                    <div className="text-grey text-p">You can cancel free of charge up to 48 hours before check-in. Cancellations beyond that time will incur a one-night stay charge. For more details, please review our policy. <a href="#">Learn More</a></div>
                    <div className="text-grey text-p">You can cancel free of charge up to 48 hours before check-in. Cancellations beyond that time will incur a one-night stay charge. For more details, please review our policy. <a href="#">Learn More</a></div>
                    <SqButton Text={"Confirm And Pay"} onClick={openModal} />


                    <CustomModal isOpen={isModalOpen} onClose={closeModal}>
                        <img width={120} className="done-icon" src={DoneIcon} />
                        <div className="modal-title">Your Booking has Confirmed</div>
                        <div className="modal-text">Thanks for choosing us!</div>
                        <div className="modal-text">Your Booking has Confirmed,</div>
                        <div className="modal-text">We will send booking details via email.</div>
                        <div className="modal-btns">
                            <SqButton Text={'View Booking'} onClick={closeModal}/>
                            <SqOutlineButton Text={'Back To Home'} onClick={NavigateToHome}/>
                        </div>
                    </CustomModal>
                </div>
            </div>
        </>
    )
}

export default BillingForm;
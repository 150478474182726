// filled Button
function SqButton(props) {
    const { Text, onClick } = props;
    return (
        <>
            <button className="sq-btn" onClick={onClick}>{Text}</button>
        </>
    );
}

// Outline Button
function SqOutlineButton(props) {
    const { Text, onClick } = props;
    return (
        <>
            <button className="sq-outline-btn" onClick={onClick}>{Text} <i className='bx bx-right-arrow-alt' ></i></button>
        </>
    );
}

// White Button
function SqWhiteButton(props) {
    const { Text, onClick } = props;
    return (
        <>
            <button className="sq-white-btn" onClick={onClick}>{Text} <i className='bx bx-right-arrow-alt' ></i></button>
        </>
    );
}

// No Outline Button
function SqNoOutlineButton(props) {
    const { Text, onClick } = props;
    return (
        <>
            <button className="sq-no-outline-btn" onClick={onClick}>{Text} <i className='bx bx-right-arrow-alt' ></i></button>
        </>
    );
}

export { SqButton, SqOutlineButton, SqWhiteButton,SqNoOutlineButton };


function InputBox(props) {
    const { type, label, value } = props;

    return (
        <>
            <div className="form-group">
                <label>{label}</label>
                <input defaultValue={value} type={type} className="form-control" placeholder="" />
            </div>
        </>
    )
}

function TextBox(props) {
    const {value, rows} = props;

    return (
        <>
            <div className="form-group">
                <textarea defaultValue={value} rows={rows} className="form-control"/>
            </div>
        </>
    )
}

function InputSelectBox(props) {
    const { options, label } = props;

    return (
        <>
            <div className="form-group">
                <label htmlFor="select">{label}</label>
                <select className="form-control form-control-select">
                    {options.map((x, i) => {
                        return (
                            <option key={i}>{x}</option>
                        )
                    })}
                </select>
            </div>
        </>
    )
}

function RadioBtn(props) {

    return (
        <>
            <label class="radio-button-container" disabled>
                <input type="radio" checked="checked" name="radio" />
                <span class="checkmark"></span>
            </label>
        </>
    )
}

export { InputBox, InputSelectBox, RadioBtn, TextBox };
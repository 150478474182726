import StarIcon from "../assets/images/star.svg"

function CardTestimonial(props) {
    const {title, text, avator, name, designation} = props;
    return (
        <div className="slide-card-wrapper">
            <div className="stars d-flex align-items-center">
                <img src={StarIcon} alt="" />
                <img src={StarIcon} alt="" />
                <img src={StarIcon} alt="" />
                <img src={StarIcon} alt="" />
                <img src={StarIcon} alt="" />
            </div>
            <div className="slide-card-title">{title}</div>
            <div className="slide-card-text">{text}</div>
            <div className="avator-box">
                <img className="avator-image" src={avator}/>
                <div className="avator-details">
                    <div className="avator-name">{name}</div>
                    <div className="avator-designation">{designation}</div>
                </div>
            </div>
        </div>
    );
};

export default CardTestimonial;
import Footer from "../components/footer";
import HeroBanner from "../components/hero-banner";
import Navbar from "../components/navbar";
import React, { useState, Fragment, useRef } from "react";

// Images
import Content1Image from "../assets/images/2in1.png"
import Content2Image from "../assets/images/restaurant-table-12-persons-with-blue-chairs.png"
import Card1Image from "../assets/images/asian-bedroom.png"
import Card2Image from "../assets/images/luxury-bedroom.png"
import Card3Image from "../assets/images/royal-luxury-bedroom-white-with-bed 1.png"
import AirportIcon from "../assets/images/Icons/car.svg"
import HousekeepingIcon from "../assets/images/Icons/cleaner.svg"
import BreakfastIcon from "../assets/images/Icons/breakfast.svg"
import WifiIcon from "../assets/images/Icons/wifi.svg"
import LaundryIcon from "../assets/images/Icons/tumble-dry.svg"
import ParkingIcon from "../assets/images/Icons/parking.svg"
import HeroBg from "../assets/images/hero-bg.png"


import { SqButton, SqOutlineButton, SqWhiteButton } from "../components/button";

import "../assets/css/home.css"
import "../assets/css/main.css"


import RoomCard from "../components/room-card";
import IconCard from "../components/icon-card";
import ExploreMore from "../components/explore";
import AvailabilityBox from "../components/availabilityBox";
import SliderTestimonial from "../components/slick-carosel";
import { useNavigate } from "react-router-dom";

function HomePage() {
    const SuitesData = [
        {
            image: Card1Image,
            title: "Deluxe Oceanview Suite",
            description: "Experience opulent comfort, featuring panoramic vistas, sophisticated furnishings, and premium amenities for an unparalleled stay"
        },
        {
            image: Card2Image,
            title: "Premium Suite",
            description: "Unwind in style with our Premium Suite, a haven of refined comfort and exclusive amenities, ensuring a truly elevated stay for travelers."
        },
        {
            image: Card3Image,
            title: "Luxury Grande Suite",
            description: "Experience opulence in our Luxury Grande Suite, where lavish amenities and elegant design converge for an indulgent stay."
        },
    ];
    const ServicesData = [
        {
            icon: AirportIcon,
            title: "Airport Pick-up Service",
            text: "Seamless arrivals with our exclusive Airport Pick-up Service for a stress-free start to your stay."
        },
        {
            icon: LaundryIcon,
            title: "Laundry Service",
            text: "Relax as we take care of your garments with precision through our top-notch Laundry Service."
        },
        {
            icon: BreakfastIcon,
            title: "Complimentary Breakfast",
            text: "Kickstart your morning with a complimentary breakfast, featuring a variety of delicious options for every palate."
        },
        {
            icon: WifiIcon,
            title: "Wifi & Internet",
            text: "Stay effortlessly connected with high-speed Wifi & Internet throughout your delightful stay."
        },
        {
            icon: HousekeepingIcon,
            title: "Housekeeping Service",
            text: "Experience top-tier cleanliness and comfort with our attentive Housekeeping Service."
        },
        {
            icon: ParkingIcon,
            title: "Private Parking Space",
            text: "Enjoy peace of mind with secure and private parking while you indulge in our luxurious retreat."
        }
        
        
    ];
    const slider1 = useRef(null);

    const previous = () => {
        slider1.current.slickNext();
    };

    const next = () => {
        slider1.current.slickPrev();
    };

    const navigate = useNavigate();

    const NavigateToRooms = () => {
        navigate('/rooms');
    };
    const NavigateToDinning = () => {
        navigate('/dinning');
    };

    return (
        <>
            <div className="container">
                <Navbar scrollValue={200} />
                <HeroBanner heroBg={HeroBg} showButton={true} heroTitle={"Find Perfect Vacation\nWith Seasons Heritage"} heroDescription={"Discover the epitome of luxury and relaxation at Seasons Heritage, where your perfect vacation awaits in every season."} />
                <AvailabilityBox />

                {/* Page Center Content */}
                <div className="page-content-1 row align-items-center">
                    <div className="col-lg-6 content-1-image">
                        <img src={Content1Image} />
                    </div>
                    <div className="col-lg-6 content-1-text">
                        <div className="content-1-heading">Southwest Austin's Newest Luxury Hotel</div>
                        <div className="content-1-para text-p">Experience unrivaled sophistication at Southwest Austin's newest luxury hotel. Immerse yourself in contemporary elegance, exceptional amenities, and personalized service.</div>
                        <div className="content-1-para text-p">Elevate your stay, creating memories that define modern luxury in the heart of Austin.</div>
                        <SqOutlineButton Text={"Explore More"} onClick={NavigateToRooms}/>
                    </div>
                </div>

                <div className="page-content-2 row align-items-center">
                    <div className="col-lg-6 content-2-text">
                        <div className="content-2-heading">Experience Exquisite Cuisine From All Over The World</div>
                        <div className="content-2-para text-p">Savor a global culinary journey at our establishment, where you can indulge in exquisite cuisine from all corners of the world.</div>
                        <div className="content-2-para text-p"> Embark on a culinary exploration and treat your taste buds to an international feast.</div>
                        <SqWhiteButton Text={"Explore More"} onClick={NavigateToDinning}/>
                    </div>
                    <div className="col-lg-6 content-2-image">
                        <img src={Content2Image} />
                    </div>
                </div>

                {/* Room Cards */}

                <div className="room-cards-container">
                    <div className="card-container-header">
                        <div className="card-container-heading">Select Your Best Suites</div>
                        <SqOutlineButton Text={"Discover All Suites"} onClick={NavigateToRooms} />
                    </div>

                    <div className="row">
                        {SuitesData.map((x, i) => {
                            return (
                                <div className="col-xl-4" key={i}>
                                    <RoomCard showprice={false} image={x.image} title={x.title} description={x.description} />
                                </div>
                            )
                        })}
                    </div>
                </div>

                {/* Page Content */}
                <div className="page-content-3 row align-items-center">
                    <div className="col-lg-6 content-3-image">
                    </div>
                    <div className="col-lg-6 content-3-text">
                        <div className="content-3-heading">Ultimate Luxury That Provide You Real Comfort</div>
                        <div className="content-3-para text-p">Discover ultimate luxury that goes beyond expectations, providing you with real comfort and an unparalleled experience that transcends the ordinary.</div>
                        <SqWhiteButton Text={"Explore More"} onClick={NavigateToRooms}/>
                    </div>

                </div>

                {/* Services */}
                <div className="services-container">
                    <div className="service-heading">We Will Provide You Best Experience</div>
                    <div className="service-text">Unmatched experiences await. Our content captivates, ensuring your undivided attention.</div>

                    <div className="icon-card-container row">
                        {ServicesData.map((x, i) => {
                            return (
                                <div key={i} className="col-lg-4 col-md-6 icon-card">
                                    <IconCard icon={x.icon} text={x.text} title={x.title} />
                                </div>
                            )
                        })}
                    </div>
                </div>

                {/* Testinomeial */}
                <section className="testinomeial-wrapper">
                    <div className="row align-items-center testinomeial-header">
                        <div className="col-md-8">
                            <div className="testinomeial-title">Feelings Of Our happy Clients</div>
                            <div className="testinomeial-text text-p">Client happiness speaks volumes. Our content captivates, leaving a lasting impact.</div>
                        </div>
                        <div className="col-md-4 wrapper__arrow d-flex align-items-center">
                            <div onClick={next} className="icon pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="#5f6e5b"><path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path></svg>
                            </div>
                            <div onClick={previous} className="icon ml-3 pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="#5f6e5b"><path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path></svg>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 my-auto">
                            <SliderTestimonial slider1={slider1} />
                        </div>
                    </div>
                </section>

                {/* Explore Section */}
                <ExploreMore />

                <Footer />
            </div>
        </>
    )
}

export default HomePage;
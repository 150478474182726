import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import HomePage from "../screens/home";
import RoomsPage from "../screens/rooms";
import ErrorPage from "../screens/error";
import RoomDetail from "../screens/roomDetail";
import BillingPage from "../screens/billingPage";
import AboutUs from "../screens/aboutUsPage";
import Dinning from "../screens/dinning";
import ContactUs from "../screens/contactUs";


function AppRouter(){

    return(
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/rooms" element={<RoomsPage/>}/>
                    <Route path="/room-detail" element={<RoomDetail/>}/>
                    <Route path="/billing" element={<BillingPage/>}/>
                    <Route path="about" element={<AboutUs/>}/>
                    <Route path="dinning" element={<Dinning/>}/>
                    <Route path="contact" element={<ContactUs/>}/>
                    <Route path="*" element={<ErrorPage/>}/>
                </Routes>
            </Router>
        </>
    )
}

export default AppRouter;
import { useNavigate } from "react-router-dom";
import { SqButton } from "./button";

function HeroBanner(props) {
    const { heroTitle, heroDescription, showButton, heroBg } = props;

    const navigate = useNavigate();

    const NavigateToRooms = () => {
        navigate('/rooms');
    };

    return (
        <>
            <div className="container">
                <div className="hero-container" style={{backgroundImage: `url(${heroBg})`}}>
                    <div className="row">
                        <div className="col-sm-2 col-1"></div>
                        <div className="hero-title col-sm-8 col-10">{heroTitle}</div>
                        <div className="col-sm-2 col-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2 col-1"></div>
                        <div className="hero-description text-p col-sm-8 col-10">{heroDescription}</div>
                        <div className="col-sm-2 col-1"></div>
                    </div>
                    {showButton && (
                        <div className="d-flex justify-content-center">
                            <SqButton Text={"Book Now"} onClick={NavigateToRooms}/>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default HeroBanner;
import Slider from "react-slick";
import CardTestimonial from "./silde-card";

// Images
import avator1 from "../assets/images/avator1.png";
import avator2 from "../assets/images/avator2.png";
import avator3 from "../assets/images/avator3.png";

const SliderTestimonial = (props) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const testimonialData = [
    {
      title: "“Unbelievable Hospitality!”",
      text: "The hotel's impeccable hospitality and attention to detail left me truly amazed. The layout and content showcase a commitment to excellence.",
      avator: avator1,
      avatorName: "Albert Flores",
      designation: "Founder",
    },
    {
      title: "“Truly Outstanding Experience!”",
      text: "Every aspect of my stay was truly outstanding. The well-crafted content and thoughtful layout added to the overall amazing experience.",
      avator: avator2,
      avatorName: "Cody Fisher",
      designation: "Owner & Founder",
    },
    {
      title: "“A Gem Among Hotels!”",
      text: "Undoubtedly one of the best hotels where I've stayed. The captivating layout and informative content make it a true gem.",
      avator: avator3,
      avatorName: "Jenny Wilson",
      designation: "Entrepreneur",
    },
    {
      title: "“Unbelievable Hospitality!”",
      text: "The hotel's impeccable hospitality and attention to detail left me truly amazed. The layout and content showcase a commitment to excellence.",
      avator: avator1,
      avatorName: "Albert Flores",
      designation: "Founder",
    },
    {
      title: "“Truly Outstanding Experience!”",
      text: "Every aspect of my stay was truly outstanding. The well-crafted content and thoughtful layout added to the overall amazing experience.",
      avator: avator2,
      avatorName: "Cody Fisher",
      designation: "Owner & Founder",
    },
    {
      title: "“A Gem Among Hotels!”",
      text: "Undoubtedly one of the best hotels where I've stayed. The captivating layout and informative content make it a true gem.",
      avator: avator3,
      avatorName: "Jenny Wilson",
      designation: "Entrepreneur",
    }
        
  ];

  return (
    <div className="wrapper__slider-testimonial">
      <Slider ref={props.slider1} {...settings} className="wrap">
        {testimonialData.map((x, i) => {
          return (
            <div key={i} className="items">
              <CardTestimonial title={x.title} text={x.text} avator={x.avator} name={x.avatorName} designation={x.designation} />
            </div>
          )
        })}
      </Slider>
    </div>
  );
};

export default SliderTestimonial;
import ExploreImage1 from "../assets/images/restaurant-with-tables-chairs.png"
import ExploreImage2 from "../assets/images/livingroom.png"
import ExploreImage3 from "../assets/images/beautiful-room.png"
import ExploreImage4 from "../assets/images/breakfast.png"
import ExploreImage5 from "../assets/images/restaurant-table-12-persons.png"
import ExploreImage6 from "../assets/images/luxury-bedroom-suite.png"
import ExploreImage7 from "../assets/images/spanish-national.png"
import ExploreImage8 from "../assets/images/clean-hotel-rooms.png"
import WhiteLogo from "../assets/images/Icons/Logo Icon.svg"
import {SqWhiteButton} from "./button"
import { useNavigate } from "react-router-dom"


function ExploreMore() {
    const navigate = useNavigate();

    const NavigateToRooms = () => {
        navigate('/rooms');
    };

    return (
        <>
            <div className="explore-container row">
                <div className="col-xl-4 explore-images">
                    <div className="row">
                        <div className="col-6 explore-img">
                            <img src={ExploreImage1} />
                        </div>
                        <div className="col-6 explore-img">
                            <img src={ExploreImage2} />
                        </div>
                        <div className="col-6 explore-img">
                            <img src={ExploreImage3} />
                        </div>
                        <div className="col-6 explore-img">
                            <img src={ExploreImage4} />
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 explore-text">
                    <img src={WhiteLogo} />
                    <div className="explore-bold">Authentic Impressions</div>
                    <SqWhiteButton Text={"Explore More"} onClick={NavigateToRooms}/>
                </div>
                <div className="col-xl-4 explore-images">
                    <div className="row">
                        <div className="col-6 explore-img">
                            <img src={ExploreImage5} />
                        </div>
                        <div className="col-6 explore-img">
                            <img src={ExploreImage6} />
                        </div>
                        <div className="col-6 explore-img">
                            <img src={ExploreImage7} />
                        </div>
                        <div className="col-6 explore-img">
                            <img src={ExploreImage8} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExploreMore;

import Logo from "../assets/images/Icons/logo.svg"
import "../assets/css/components.css";
import { NavLink } from "react-router-dom";

function Footer() {


    return (
        <>
            <div className="footer">
                <div className="footer-centre">
                    <img className="foot-logo" src={Logo} width={200} />
                    <div className="foot-text text-p">(312) 023-3911</div>
                    <div className="foot-text text-p">info@seasonsheritage.com</div>
                    <div className="foot-text text-p">4237 Washington Ave. Manchester, Kentucky 57835</div>
                    <div className="foot-heading">We Are Looking <br /> Forward To Your Visit!</div>

                    <div className="foot-links">
                        <ul>
                            <NavLink to={'/'}><li className="text-p">Home</li></NavLink>
                            <NavLink to={'/about'}><li className="text-p">About</li></NavLink>
                            <NavLink to={'/dinning'}><li className="text-p">Dinning</li></NavLink>
                            <NavLink to={'/'}><li className="text-p">Events</li></NavLink>
                            <NavLink to={'/'}><li className="text-p">Gallery</li></NavLink>
                            <NavLink to={'/contact'}><li className="text-p">Contact Us</li></NavLink>
                        </ul>
                    </div>
                </div>

                <div className="footer-foot">
                    <div className="copyright-container">
                        <div className="copyright text-p">&copy; 2023 Seasons Heritage.</div>
                        <div className="rights text-p">All rights reserved.</div>
                    </div>
                    <div className="social-icons">
                        <a href="#!"><i className='bx bxl-facebook-circle'></i></a>
                        <a href="#!"><i className='bx bxl-instagram'></i></a>
                        <a href="#!"><i className='bx bxl-twitter'></i></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;
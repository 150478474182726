
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import BillingCard from "../components/bill-card";
import BillingForm from "../components/billingForm";
import React, { useState, Fragment, useRef } from "react";

// Images


import {SqButton, SqOutlineButton, SqWhiteButton} from "../components/button";


function BillingPage() {

    return (
        <>
            <div className="container no-hero">
                <Navbar scrollValue={0}/>

                {/* Room Details/Booking */}
                <div className="row room-booking-details-container">
                    <div className="col-md-8 room-details-card">
                        <BillingForm />
                    </div>
                    <div className="col-md-4 room-bill-cal">
                        <BillingCard />
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default BillingPage;
import Footer from "../components/footer";
import HeroBanner from "../components/hero-banner";
import Navbar from "../components/navbar";
import AvailabilityBox from "../components/availabilityBox";
import React, { useState, Fragment, useRef } from "react";

// Images
import Card1Image from "../assets/images/luxury-bedroom-suite-lg.png"
import Card2Image from "../assets/images/royal-luxury-bedroom-lg.png"
import Card3Image from "../assets/images/asian-bedroom-lg.png"
import Card4Image from "../assets/images/red-room-lg.png"
import Card5Image from "../assets/images/luxury-bedroom-white-lg.png"
import Card6Image from "../assets/images/beautiful-room-lg.png"
import HeroBg from "../assets/images/rooms-hero-bg.png"
import Arrow from "../assets/images/Icons/arrow.svg"

import {SqButton, SqOutlineButton, SqWhiteButton} from "../components/button";

import "../assets/css/home.css"


import RoomCard from "../components/room-card";

function RoomsPage() {
    const SuitesData = [
        {
            image: Card1Image,
            title: "Deluxe Oceanview Suite",
            description: "Indulge in the opulence of our Deluxe Oceanview Suite. Unwind in comfort and style, with every detail meticulously crafted for an unforgettable experience.",
             price: "$600",
        },
        {
            image: Card2Image,
            title: "Luxury Grande Suite",
            description: "Indulge in our Grande Suite—where sophistication meets comfort. Immerse yourself in curated luxury for an indulgent stay.",
            price: "$1200",
        },
        {
            image: Card3Image,
            title: "Premium Suite",
            description: "Discover elegance in our Premium Suite, offering a harmonious blend of style. Unwind in a space designed for both relaxation and productivity.",
            price: "$500",
        },
        {
            image: Card4Image,
            title: "Studio Apartment City View",
            description: "Embrace the vibrant city atmosphere in our Studio Apartment with a captivating city view. Experience modern living with all the comforts of home.",
            price: "$850",
        },
        {
            image: Card5Image,
            title: "Royal Luxury Sea View Suite",
            description: "Revel in regal luxury with our Royal Sea View Suite, where majestic views and refined interiors create an unparalleled setting. Your royal retreat awaits.",
            price: "$900",
        },
        {
            image: Card6Image,
            title: "Luxury Premium Suite",
            description: "Experience the epitome of luxury in our Premium Suite, offering premium amenities. Indulge in a truly elevated stay for an unforgettable escape.",
            price: "$1500",
        }
        
    ];
    const heroDescription = (
        <span>
          Home <img src={Arrow} alt="Arrow" />
          Rooms
        </span>
      );

    return (
        <>
            <div className="container">
                <Navbar scrollValue={200}/>
                <HeroBanner heroBg={HeroBg} showButton={false} heroTitle={"Rooms & Suites"} heroDescription={heroDescription}/>
                <AvailabilityBox />

                {/* Room Cards */}

                <div className="room-cards-container">
                    <div className="big-card-container-header">
                        <div className="card-container-heading">Explore Our Refined Accommodation Option And Find The Perfect Space For Your Stay</div>
                        <div className="card-container-text">Discover our refined accommodation options and find the perfect space for your stay. Explore a collection of thoughtfully designed rooms, each offering a blend of comfort and sophistication.</div>
                    </div>

                    <div className="row">
                        {SuitesData.map((x, i) => {
                            return (
                                <div className="col-lg-6" key={i}>
                                    <RoomCard showprice={true} price={x.price} image={x.image} title={x.title} description={x.description} />
                                </div>
                            )
                        })}
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default RoomsPage;
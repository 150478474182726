
function IconCard(props){

    const {icon, title, text} = props;

    return(
        <>
            <div className="icon-card">
                <img src={icon} width={40}/>
                <div className="icon-card-title">{title}</div>
                <div className="icon-card-text">{text}</div>
            </div>
        </>
    )
}

export default IconCard;
import GuestIcon from "../assets/images/Icons/users.svg";
import BedIcon from "../assets/images/Icons/Bed.svg";
import MaximizeIcon from "../assets/images/Icons/Maximize.svg";
import {SqNoOutlineButton} from "./button";
import { useNavigate } from "react-router-dom";

function RoomCard(props) {
    const { image, title, description, size, price, showprice } = props;

    const navigate = useNavigate();
    const NavigateToRoomDetail = () => {
        navigate('/room-detail');
    };

    return (
        <>
            <div className="room-card">
                <div className="card-image">
                    <img src={image} />
                </div>
                <div className="card-content">
                    <div className="card-title">{title}</div>
                    <div className="card-sizes">
                        <div className="card-size">
                            <img src={MaximizeIcon} />
                            <div className="icon-text">95 m<sup>2</sup></div>
                        </div>
                        <div className="card-size">
                            <img src={BedIcon} />
                            <div className="icon-text">1 King Size Bed</div>
                        </div>
                        <div className="card-size">
                            <img src={GuestIcon} />
                            <div className="icon-text">2 Guests</div>
                        </div>
                    </div>
                    <div className="card-description">
                        {description}
                    </div>
                    {showprice && (
                         <div className="room-price"><span>{price}</span>/per night</div>
                    )}
                    <SqNoOutlineButton onClick={NavigateToRoomDetail} Text={"Discover More"}/>
                </div>
            </div>
        </>
    )
}

export default RoomCard;
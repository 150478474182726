import Footer from "../components/footer";
import HeroBanner from "../components/hero-banner";
import Navbar from "../components/navbar";
import React, { useState, Fragment, useRef } from "react";

// Images
import Dinning1Image from "../assets/images/dinning-1.png"
import Dinning2Image from "../assets/images/dinnig-2.png"
import HeroBg from "../assets/images/dinning-bg.png"
import Thumbnail from "../assets/images/dinning-middle.png"
import Arrow from "../assets/images/Icons/arrow.svg"


import { SqButton, SqOutlineButton, SqWhiteButton } from "../components/button";

import "../assets/css/home.css"
import "../assets/css/main.css"
import { useNavigate } from "react-router-dom";

function Dinning() {
    const heroDescription = (
        <span>
            Home <img src={Arrow} alt="Arrow" />
            Dinning
        </span>
    );
    const navigate = useNavigate();

    const NavigateToRooms = () => {
        navigate('/rooms');
    };

    return (
        <>
            <div className="container">
                <Navbar scrollValue={200}/>
                <HeroBanner heroBg={HeroBg} showButton={false} heroTitle={"Dinning"} heroDescription={heroDescription} />

                {/* Page Content */}
                <div className="about-content row align-items-center">
                    <div className="col-lg-6 content-1-text">
                        <div className="content-1-heading">Experience Exquisite Cuisine At The Restaurant</div>
                        <div className="content-1-para text-p">Embark on a culinary journey and experience exquisite cuisine at our restaurant. Indulge in a delightful blend of flavors, crafted to satisfy your palate.</div>
                        <div className="content-1-para text-p">Elevate your dining experience with us.</div>
                        <SqOutlineButton Text={"Explore More"} onClick={NavigateToRooms}/>
                    </div>
                    <div className="col-lg-6 content-1-image">
                        <img src={Dinning1Image} />
                    </div>
                </div>

                {/* Main Video Box */}
                <div className="video-box">
                    <div className="about-video"><img src={Thumbnail} /></div>
                </div>

                {/* Page Content */}
                <div className="about-content row align-items-center">
                    <div className="col-lg-6 content-1-image">
                        <img src={Dinning2Image} />
                    </div>
                    <div className="col-lg-6 content-1-text">
                        <div className="content-1-heading">Best Rooftop Restaurant And Special Bar</div>
                        <div className="content-1-para text-p">Elevate your dining experience at our rooftop restaurant and special bar. Enjoy breathtaking views while savoring the best culinary delights and curated drinks.</div>
                        <div className="content-1-para text-p">Unwind in style at our unparalleled rooftop destination.</div>
                        <SqOutlineButton Text={"Explore More"} onClick={NavigateToRooms}/>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Dinning;
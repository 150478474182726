import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { SqButton } from "./button"

import "../assets/css/components.css";
import "boxicons/css/boxicons.min.css";

// Images
import Logo from "../assets/images/Icons/logo.svg";
import WhiteLogo from "../assets/images/logo-white.svg";
import CallIcon from "../assets/images/Icons/call-icon.svg";
import WhiteCall from "../assets/images/Icons/call-white.svg";

function Navbar(props) {
  const { scrollValue } = props;
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    let navLinks = document.querySelector(".nav-links");
    let menuOpenBtn = document.querySelector(".navbar .bx-menu");
    let menuCloseBtn = document.querySelector(".nav-links .bx-x");

    menuOpenBtn.onclick = function () {
      navLinks.style.left = "0";
    };

    menuCloseBtn.onclick = function () {
      navLinks.style.left = "-100%";
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= scrollValue) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClass = scrolled ? "navbar scrolled" : "navbar";
  const navLinksClass = scrolled ? "nav-links scrolled" : "nav-links";
  const logoClass = scrolled ? "logo scrolled" : "logo";
  const callLogoClass = scrolled ? "callLogo scrolled" : "callLogo";
  const contactNoClass = scrolled ? "contact-no scrolled" : "contact-no";

  const logoSrc = scrolled ? Logo : WhiteLogo;
  const callIconSrc = scrolled ? CallIcon : WhiteCall;

  const navigate = useNavigate();

  const NavigateToContact = () => {
    navigate('/contact');
  };

  return (
    <nav>
      <div className={navbarClass}>
        <i className="bx bx-menu"></i>

        <div className={navLinksClass}>
          <div className="sidebar-logo">
            <span className="logo-name">
              <NavLink to={"/"}><img src={Logo} width={80} alt="Logo" /></NavLink>
            </span>
            <i className="bx bx-x"></i>
          </div>
          <ul className="links">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/about">About Us</NavLink>
            </li>
            <li>
              <NavLink to="/dinning">Dinning</NavLink>
            </li>
            <li>
              <NavLink to="/rooms">Rooms</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact Us</NavLink>
            </li>
          </ul>
        </div>
        <div className={logoClass}>
          <img src={logoSrc} width={50} alt="Logo" />
        </div>
        <div className="contact-btns">
          <div className={contactNoClass}>
            <img className={callLogoClass} width={50} src={callIconSrc} alt="Call Icon" />
            <p>(303) 555-4923</p>
          </div>
          <SqButton Text={"Enquire Now"} onClick={NavigateToContact} />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

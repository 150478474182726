import GuestIcon from "../assets/images/Icons/users.svg";
import BedIcon from "../assets/images/Icons/Bed.svg";
import MaximizeIcon from "../assets/images/Icons/Maximize.svg";
import CleanIcon from "../assets/images/Icons/clean.svg";
import LocationIcon from "../assets/images/Icons/location.svg";
import KeyIcon from "../assets/images/Icons/key.svg";
import CalenderIcon from "../assets/images/Icons/calendar-black.svg";
import AcIcon from "../assets/images/Icons/Ac.svg";
import WifiIcon from "../assets/images/Icons/wifi-black.svg";
import TvIcon from "../assets/images/Icons/television.svg";
import WashingIcon from "../assets/images/Icons/washing.svg";
import DrinkIcon from "../assets/images/Icons/welcome-drink.svg";
import DryerIcon from "../assets/images/Icons/dryer.svg";
import LockerIcon from "../assets/images/Icons/lockers.svg";
import WorkspaceIcon from "../assets/images/Icons/workspace.svg";
import OvenIcon from "../assets/images/Icons/oven.svg";
import FridgeIcon from "../assets/images/Icons/refrigerator.svg";
import HorizontalCalender from "../assets/images/calender.png";
import VerticalCalender from "../assets/images/vertical-calendar.png";
import StarIcon from "../assets/images/star.svg";
import avator1 from "../assets/images/avator1.png";
import avator2 from "../assets/images/avator3.png";
import { SqOutlineButton } from "./button";


function RoomDetailCard(props) {
    // Get the current viewport width
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    // Set the threshold for max-width
    const maxWidthThreshold = 768;

    // Conditionally render vertical or horizontal image based on the max-width
    const renderCalendarImage = () => {
        if (viewportWidth <= maxWidthThreshold) {
            return <img src={VerticalCalender} alt="Vertical Calendar" />;
        } else {
            return <img src={HorizontalCalender} alt="Horizontal Calendar" />;
        }
    };
    return (
        <>
            <div className="detail-card-container">
                <div className="detail-card-content">
                    <div className="detail-card-title">Premium Suite</div>
                    <div className="card-sizes">
                        <div className="card-size">
                            <img src={MaximizeIcon} />
                            <div className="icon-text">95 m<sup>2</sup></div>
                        </div>
                        <div className="card-size">
                            <img src={BedIcon} />
                            <div className="icon-text">1 King Size Bed</div>
                        </div>
                        <div className="card-size">
                            <img src={GuestIcon} />
                            <div className="icon-text">2 Guests</div>
                        </div>
                    </div>
                    <div className="detail-card-description">
                    Step into opulence with our Premium Suite, a haven of refined elegance and modern comfort. From sophisticated design to premium amenities, every detail is thoughtfully curated to elevate your stay. Unwind in spacious luxury, where tranquility and style converge. The Premium Suite offers an unparalleled experience, ensuring a memorable retreat in the lap of indulgence.
                    </div>
                </div>
                {/* ------------------------------------------------------------- */}
                <div className="detail-card-features">
                    <div className="feature-1-box">
                        <div className="feature-icon">
                            <img src={CleanIcon} />
                        </div>
                        <div className="feature-1-text">
                            <div className="feature-bold text-p">Enhanced Clean</div>
                            <div className="feature-light">This hotel committed 5-step enhanced cleaning process.</div>
                        </div>
                    </div>
                    <div className="feature-1-box">
                        <div className="feature-icon">
                            <img src={LocationIcon} />
                        </div>
                        <div className="feature-1-text">
                            <div className="feature-bold text-p">Great Location</div>
                            <div className="feature-light">95% of recent guests give the location a 5-star rating.</div>
                        </div>
                    </div>
                    <div className="feature-1-box">
                        <div className="feature-icon">
                            <img src={KeyIcon} />
                        </div>
                        <div className="feature-1-text">
                            <div className="feature-bold text-p">Great check-in-experience</div>
                            <div className="feature-light">90% of recent guests gave the check-in process a 5-star rating.</div>
                        </div>
                    </div>
                    <div className="feature-1-box">
                        <div className="feature-icon">
                            <img width={30} src={CalenderIcon} />
                        </div>
                        <div className="feature-1-text">
                            <div className="feature-bold text-p">Free cancellation until 3.00 PM ON Dec 7, 2023</div>
                        </div>
                    </div>
                </div>
                {/* ------------------------------------------------------------- */}
                <div className="detail-card-features">
                    <div className="detail-card-feature-title">What This Place Offers</div>
                    <div className="row">
                        <div className="feature-1-box col-6 col-md-4">
                            <div className="feature-icon">
                                <img src={AcIcon} />
                            </div>
                            <div className="feature-1-text">
                                <div className="feature-black">Air Conditioner</div>
                            </div>
                        </div>
                        <div className="feature-1-box col-6 col-md-4">
                            <div className="feature-icon">
                                <img src={WifiIcon} />
                            </div>
                            <div className="feature-1-text">
                                <div className="feature-black">Wifi</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="feature-1-box col-6 col-md-4">
                            <div className="feature-icon">
                                <img src={TvIcon} />
                            </div>
                            <div className="feature-1-text">
                                <div className="feature-black">Smart TV</div>
                            </div>
                        </div>
                        <div className="feature-1-box col-6 col-md-4">
                            <div className="feature-icon">
                                <img src={WashingIcon} />
                            </div>
                            <div className="feature-1-text">
                                <div className="feature-black">Laundry Service</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="feature-1-box col-6 col-md-4">
                            <div className="feature-icon">
                                <img src={DrinkIcon} />
                            </div>
                            <div className="feature-1-text">
                                <div className="feature-black">Welcome Drink</div>
                            </div>
                        </div>
                        <div className="feature-1-box col-6 col-md-4">
                            <div className="feature-icon">
                                <img src={DryerIcon} />
                            </div>
                            <div className="feature-1-text">
                                <div className="feature-black">Hair Dryer</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="feature-1-box col-6 col-md-4">
                            <div className="feature-icon">
                                <img src={LockerIcon} />
                            </div>
                            <div className="feature-1-text">
                                <div className="feature-black">Private Locker</div>
                            </div>
                        </div>
                        <div className="feature-1-box col-6 col-md-4">
                            <div className="feature-icon">
                                <img src={WorkspaceIcon} />
                            </div>
                            <div className="feature-1-text">
                                <div className="feature-black">Dedicated Workspace</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="feature-1-box col-6 col-md-4">
                            <div className="feature-icon">
                                <img src={OvenIcon} />
                            </div>
                            <div className="feature-1-text">
                                <div className="feature-black">Microwave</div>
                            </div>
                        </div>
                        <div className="feature-1-box col-6 col-md-4">
                            <div className="feature-icon">
                                <img src={FridgeIcon} />
                            </div>
                            <div className="feature-1-text">
                                <div className="feature-black">Refrigerator</div>
                            </div>
                        </div>
                    </div>
                    <SqOutlineButton Text={"Show All 20 Amenities"} />
                </div>
                {/* ------------------------------------------------------------- */}
                <div className="availability-calender">
                    <div className="calender-title">Availability</div>
                    {renderCalendarImage()}
                </div>
                {/* ------------------------------------------------------------- */}
                <div className="review-card-wrapper">
                    <div className="review-card-heading">Customer Reviews</div>
                    <div className="review-card">
                        <div className="avator-box">
                            <img className="avator-image" src={avator1} />
                            <div className="avator-details">
                                <div className="avator-name"><b>Alexa Mate</b></div>
                                <div className="review-stars d-flex align-items-center">
                                    <img src={StarIcon} alt="" />
                                    <img src={StarIcon} alt="" />
                                    <img src={StarIcon} alt="" />
                                    <img src={StarIcon} alt="" />
                                    <img src={StarIcon} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="review-content">
                            <div className="review-title">Fabulous Hotel, I raelly Like It</div>
                            <div className="review-text">The hotel's impeccable hospitality and attention to detail left me truly amazed. The layout and content showcase a commitment to excellence.</div>
                        </div>
                    </div>
                    <div className="review-card">
                        <div className="avator-box">
                            <img className="avator-image" src={avator2} />
                            <div className="avator-details">
                                <div className="avator-name"><b>Johnson Willians</b></div>
                                <div className="review-stars d-flex align-items-center">
                                    <img src={StarIcon} alt="" />
                                    <img src={StarIcon} alt="" />
                                    <img src={StarIcon} alt="" />
                                    <img src={StarIcon} alt="" />
                                    <img src={StarIcon} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="review-content">
                            <div className="review-title">I am very happy with this room and staff behaviour</div>
                            <div className="review-text">Every aspect of my stay was truly outstanding. The well-crafted content and thoughtful layout added to the overall amazing experience.</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoomDetailCard;
import { useNavigate } from "react-router-dom";
import { SqButton } from "./button";
import { InputBox, InputSelectBox } from "./inputs";


function BookingCalculator(props) {
    const { price } = props
    const RoomSelectValues = ["1 Room", "2 Room", "3 Room", "4 Room", "5 Room"];
    const AdultSelectValues = [1, 2, 3, 4, 5];
    const ChildSelectValues = [1, 2, 3, 4, 5];

    const navigate = useNavigate();
    const NavigateToBilling = () => {
        navigate('/billing');
    };

    return (
        <>
            <div className="calculator-box">
                <div className="row">
                    <div className="col-12 room-price"><span>$500</span>/night</div>
                    <div className="col-12"><InputBox label={"CheckIn"} type={"date"} /></div>
                    <div className="col-12"><InputBox label={"CheckOut"} type={"date"} /></div>
                    <div className="col-12"><InputSelectBox label={"Rooms"} options={RoomSelectValues} /></div>
                    <div className="col-6"><InputSelectBox label={"Adults"} options={AdultSelectValues} /></div>
                    <div className="col-6"><InputSelectBox label={"Child"} options={ChildSelectValues} /></div>
                    <div className="col-12 book-btn"><SqButton Text={"Book Now"} onClick={NavigateToBilling}/></div>

                    <div className="pricing-box">
                        <div className="col-12 d-flex justify-content-between align-items-center room-bill text-p"><span>$500 x 3 nights</span> <span>$1500</span></div>
                        <div className="col-12 d-flex justify-content-between align-items-center room-bill text-p"><span>Discount</span> <span className="green-text">$50</span></div>
                        <div className="col-12 d-flex justify-content-between align-items-center room-bill text-p"><span>Occupancy taxes and fees</span> <span>$18</span></div>
                    </div>

                    <div className="col-12 d-flex justify-content-between align-items-center grand-total"><span>Grand Total</span> <span>$1468</span></div>
                </div>
            </div>
        </>
    )

}

export default BookingCalculator;
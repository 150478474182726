import Footer from "../components/footer";
import HeroBanner from "../components/hero-banner";
import Navbar from "../components/navbar";
import React, { useState, Fragment, useRef } from "react";

// Images
import About1Image from "../assets/images/about-us-1.png"
import About2Image from "../assets/images/about-us-2.png"
import AirportIcon from "../assets/images/Icons/car.svg"
import HousekeepingIcon from "../assets/images/Icons/cleaner.svg"
import BreakfastIcon from "../assets/images/Icons/breakfast.svg"
import WifiIcon from "../assets/images/Icons/wifi.svg"
import LaundryIcon from "../assets/images/Icons/tumble-dry.svg"
import ParkingIcon from "../assets/images/Icons/parking.svg"
import HeroBg from "../assets/images/about-us-bg.png"
import Thumbnail from "../assets/images/thumbnail.png"
import Arrow from "../assets/images/Icons/arrow.svg"


import { SqButton, SqOutlineButton, SqWhiteButton } from "../components/button";

import "../assets/css/home.css"
import "../assets/css/main.css"

import IconCard from "../components/icon-card";
import SliderTestimonial from "../components/slick-carosel";
import { useNavigate } from "react-router-dom";

function AboutUs() {
    const ServicesData = [
        {
            icon: AirportIcon,
            title: "Airport Pick-up Service",
            text: "Seamless arrivals with our exclusive Airport Pick-up Service for a stress-free start to your stay."
        },
        {
            icon: LaundryIcon,
            title: "Laundry Service",
            text: "Relax as we take care of your garments with precision through our top-notch Laundry Service."
        },
        {
            icon: BreakfastIcon,
            title: "Complimentary Breakfast",
            text: "Kickstart your morning with a complimentary breakfast, featuring a variety of delicious options for every palate."
        },
        {
            icon: WifiIcon,
            title: "Wifi & Internet",
            text: "Stay effortlessly connected with high-speed Wifi & Internet throughout your delightful stay."
        },
        {
            icon: HousekeepingIcon,
            title: "Housekeeping Service",
            text: "Experience top-tier cleanliness and comfort with our attentive Housekeeping Service."
        },
        {
            icon: ParkingIcon,
            title: "Private Parking Space",
            text: "Enjoy peace of mind with secure and private parking while you indulge in our luxurious retreat."
        }
        
        
    ];
    const slider1 = useRef(null);

    const previous = () => {
        slider1.current.slickNext();
    };

    const next = () => {
        slider1.current.slickPrev();
    };

    const heroDescription = (
        <span>
            Home <img src={Arrow} alt="Arrow" />
            About Us
        </span>
    );

    const navigate = useNavigate();

    const NavigateToRooms = () => {
        navigate('/rooms');
    };

    return (
        <>
            <div className="container">
                <Navbar scrollValue={200} />
                <HeroBanner heroBg={HeroBg} showButton={false} heroTitle={"About Us"} heroDescription={heroDescription} />

                {/* Main Video Box */}
                <div className="video-box">
                    <div className="video-title">Escape To A World Of Luxury Hotels</div>
                    <div className="video-text text-p">Enjoy stunning view of the city skyline or the ocean from your private balcony. Our friendly staff provide exeptional services making you feel at home during your stay. Book now and make yournext vacation unforgetable at our hotel. Wheather you're travelling for business or pleasure, our hotel offers the ultimate in relaxation and comfort.</div>
                    <div className="about-video"><img src={Thumbnail} /></div>
                </div>

                {/* Page Content */}
                <div className="about-content row align-items-center">
                    <div className="col-lg-7 content-1-image">
                        <img src={About1Image} />
                    </div>
                    <div className="col-lg-5 content-1-text">
                        <div className="content-1-heading">What Services We Offer For You</div>
                        <div className="content-1-para text-p">Indulge in unparalleled hospitality at our hotel, where attentive services and luxurious amenities ensure a memorable stay for business or leisure.</div>
                        <div className="content-1-para text-p">Experience comfort beyond expectation.</div>
                        <SqOutlineButton Text={"Explore More"} onClick={NavigateToRooms}/>
                    </div>
                </div>

                <div className="about-content row align-items-center">
                    <div className="col-lg-6 content-1-text">
                        <div className="content-1-heading">Book Your Stay Today And Discover</div>
                        <div className="content-1-para text-p">Book your stay today and unlock a world of comfort and luxury. Discover thoughtfully curated accommodations, exceptional service, and an unforgettable experience.</div>
                        <div className="content-1-para text-p">Your journey begins with us—reserve your perfect retreat now.</div>
                        <SqOutlineButton Text={"Book Now"} onClick={NavigateToRooms}/>
                    </div>
                    <div className="col-lg-6 content-1-image">
                        <img src={About2Image} />
                    </div>
                </div>

                {/* Services */}
                <div className="services-container">
                    <div className="service-heading">We Will Provide You Best Experience</div>
                    <div className="service-text text-p">Unmatched experiences await. Our content captivates, ensuring your undivided attention.</div>

                    <div className="icon-card-container row">
                        {ServicesData.map((x, i) => {
                            return (
                                <div key={i} className="col-lg-4 col-md-6 icon-card">
                                    <IconCard icon={x.icon} text={x.text} title={x.title} />
                                </div>
                            )
                        })}
                    </div>
                </div>

                {/* Testinomeial */}
                <section className="testinomeial-wrapper">
                    <div className="row align-items-center testinomeial-header">
                        <div className="col-md-8">
                            <div className="testinomeial-title">Feelings Of Our happy Clients</div>
                            <div className="testinomeial-text text-p">Client happiness speaks volumes. Our content captivates, leaving a lasting impact.</div>
                        </div>
                        <div className="col-md-4 wrapper__arrow d-flex align-items-center">
                            <div onClick={next} className="icon pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="#5f6e5b"><path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path></svg>
                            </div>
                            <div onClick={previous} className="icon ml-3 pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="#5f6e5b"><path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path></svg>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 my-auto">
                            <SliderTestimonial slider1={slider1} />
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    )
}

export default AboutUs;
import { SqButton } from "./button";
import CalenderIcon from "../assets/images/Icons/calendar.svg"
import UserIcon from "../assets/images/Icons/users.svg"
import { useNavigate } from "react-router-dom";

function AvailabilityBox(){

    const navigate = useNavigate();

    const NavigateToDetails = () => {
        navigate('/room-detail');
    };

    return(
        <div className="row">
        <div className="col-1"></div>
        <div className="col-10">
            <div className="check-availability row">
                <div className="col-xl-3 col-md-6 availability-box">
                    <div className="filter-icon"><img src={CalenderIcon} /></div>
                    <div className="filter-text">
                        <div className="filter-text-heading">Check In</div>
                        <div className="filter-text-value">Dec 25, 2023</div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 availability-box">
                    <div className="filter-icon"><img src={CalenderIcon} /></div>
                    <div className="filter-text">
                        <div className="filter-text-heading">Check Out</div>
                        <div className="filter-text-value">Dec 29, 2023</div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 availability-box">
                    <div className="filter-icon"><img src={UserIcon} /></div>
                    <div className="filter-text">
                        <div className="filter-text-heading">Guests</div>
                        <div className="filter-text-value">2 Adults, 1 Child</div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6 check-btn"><SqButton Text={"Check Availability"} onClick={NavigateToDetails}/></div>
            </div>
        </div>
        <div className="col-1"></div>
    </div>
    )
}

export default AvailabilityBox;
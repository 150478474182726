import React, { useState } from 'react';

const CustomModal = ({ isOpen, onClose, children }) => {
  const modalClassName = `custom-modal ${isOpen ? 'open' : ''}`;

  return (
    <div className={modalClassName}>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="modal-content">
        {children}
      </div>
    </div>
  );
};

export default CustomModal;

import CardImage from "../assets/images/room-detail-3.png"


function BillingCard(props) {

    return (
        <>
            <div className="bill-card">
                <div className="row">
                    <div className="col-12 suite-name">Premium Suite</div>

                    <img src={CardImage}/>

                    <div className="pricing-box">
                        <div className="col-12 d-flex justify-content-between align-items-center room-bill text-p"><span>$500 x 3 nights</span> <span>$1500</span></div>
                        <div className="col-12 d-flex justify-content-between align-items-center room-bill text-p"><span>Discount</span> <span className="green-text">$50</span></div>
                        <div className="col-12 d-flex justify-content-between align-items-center room-bill text-p"><span>Occupancy taxes and fees</span> <span>$18</span></div>
                    </div>

                    <div className="col-12 d-flex justify-content-between align-items-center grand-total"><span>Grand Total</span> <span>$1468</span></div>
                </div>
            </div>
        </>
    )

}

export default BillingCard;
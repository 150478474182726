import AppRouter from './router/routes';

// css
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


function App() {
  return (
    <>
      <AppRouter/>
    </>
  );
}

export default App;

import BookingCalculator from "../components/booking-calculator";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import React, { useState, Fragment, useRef } from "react";

// Images
import DetailedImage1 from "../assets/images/room-detail-1.png";
import DetailedImage2 from "../assets/images/room-detail-2.png";
import DetailedImage3 from "../assets/images/room-detail-3.png";
import RoomDetailCard from "../components/room-detail-card";
import Card1Image from "../assets/images/asian-bedroom.png"
import Card2Image from "../assets/images/luxury-bedroom.png"
import Card3Image from "../assets/images/royal-luxury-bedroom-white-with-bed 1.png"

import {SqButton, SqOutlineButton, SqWhiteButton} from "../components/button";
import RoomCard from "../components/room-card";

function RoomDetails() {
    const SuitesData = [
        {
            image: Card1Image,
            title: "Deluxe Oceanview Suite",
            description: "Experience opulent comfort, featuring panoramic vistas, sophisticated furnishings, and premium amenities for an unparalleled stay"
        },
        {
            image: Card2Image,
            title: "Premium Suite",
            description: "Unwind in style with our Premium Suite, a haven of refined comfort and exclusive amenities, ensuring a truly elevated stay for travelers."
        },
        {
            image: Card3Image,
            title: "Luxury Grande Suite",
            description: "Experience opulence in our Luxury Grande Suite, where lavish amenities and elegant design converge for an indulgent stay."
        },
    ];
    return (
        <>
            <div className="container no-hero">
                <Navbar scrollValue={0}/>
                {/* Hero Images */}
                <div className="row hero-images-container">
                    <div className="col-md-8 room-hero-lg-image">
                        <img src={DetailedImage1} />
                    </div>
                    <div className="col-md-4 room-hero-sm-images">
                        <img src={DetailedImage2} />
                        <img className="sm-image-bottom" src={DetailedImage3} />
                    </div>
                </div>

                {/* Room Details/Booking */}
                <div className="row room-booking-details-container">
                    <div className="col-md-8 room-details-card">
                        <RoomDetailCard />
                    </div>
                    <div className="col-md-4 room-bill-cal">
                        <BookingCalculator />
                    </div>
                </div>

                {/* Room Cards */}

                <div className="room-cards-container">
                    <div className="card-container-header">
                        <div className="card-container-heading">Similar Suites</div>
                        <SqOutlineButton Text={"Discover All Suites"} />
                    </div>

                    <div className="row">
                        {SuitesData.map((x, i) => {
                            return (
                                <div className="col-xl-4" key={i}>
                                    <RoomCard showprice={false} image={x.image} title={x.title} description={x.description} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default RoomDetails;
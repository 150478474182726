import Footer from "../components/footer";
import HeroBanner from "../components/hero-banner";
import Navbar from "../components/navbar";
import React, { useState, Fragment, useRef } from "react";

// Images
import Contact1Image from "../assets/images/contact-us-1.png"
import HeroBg from "../assets/images/contact-us-bg.png"
import Arrow from "../assets/images/Icons/arrow.svg"
import CallIcon from "../assets/images/Icons/calling.svg"
import LocationIcon from "../assets/images/Icons/location.svg"
import MailIcon from "../assets/images/Icons/gmail.svg"


import { SqButton, SqOutlineButton, SqWhiteButton } from "../components/button";

import "../assets/css/home.css"
import "../assets/css/main.css"
import { InputBox, TextBox } from "../components/inputs";

function ContactUs() {

    const heroDescription = (
        <span>
            Home <img src={Arrow} alt="Arrow" />
            Contact Us
        </span>
    );

    return (
        <>
            <div className="container">
                <Navbar scrollValue={200}/>
                <HeroBanner heroBg={HeroBg} showButton={false} heroTitle={"Contact Us"} heroDescription={heroDescription} />

                {/* Contact Box */}
                <div className="contact-box">
                    <div className="contact-title">Plan Your Stay At Seasons Heritage</div>
                    <div className="contact-text text-p">Enjoy stunning view of the city skyline or the ocean from your private balcony. Our friendly staff provide exeptional services making you feel at home during your stay. Book now and make yournext vacation unforgetable at our hotel. Wheather you're travelling for business or pleasure, our hotel offers the ultimate in relaxation and comfort.</div>
                    <div className="row">
                        <div className="col-lg-4 contact-card">
                            <img width={40} src={CallIcon} />
                            <div className="contact-bold">Call Us</div>
                            <div className="contact-sm-light">(319) 555-0115</div>
                        </div>
                        <div className="col-lg-4 contact-card">
                            <img width={40} src={LocationIcon} />
                            <div className="contact-bold">Address</div>
                            <div className="contact-sm-light">8504 Preston Rd, Inglewood, Maine</div>
                        </div>
                        <div className="col-lg-4 contact-card">
                            <img width={40} src={MailIcon} />
                            <div className="contact-bold">Email Us</div>
                            <div className="contact-sm-light">seasonsheritage@demo.com</div>
                        </div>
                    </div>
                </div>

                {/* Contact Form */}
                <div className="contact-form-box row">
                    <div className="col-md-6 contact-form-image">
                        <img src={Contact1Image}/>
                    </div>
                    <div className="col-md-6 contact-form">
                        <div className="contact-form-title">Let's Start A Conversation</div>
                        <div className="contact-form-text text-p">Initiate dialogue and embark on an engaging conversation with us, where your voice matters.</div>

                        <div className="col-12"><InputBox type={"text"} label={"Name"} value={"Perry Wilson"} /></div>
                        <div className="col-12"><InputBox type={"email"} label={"Email Address"} value={"perry.wilson@example.com"} /></div>
                        <div className="col-12"><InputBox type={"text"} label={"Mobile Number"} value={"(219) 555-0114"} /></div>
                        <div className="col-12"><TextBox value={"Message"} rows={5}/></div>
                        <SqButton Text={"Send Your Message"}/>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default ContactUs;